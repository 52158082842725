import React from "react";

const ContactForm = () => {
    // const [status, setStatus] = useState("Submit");
    // const handleSubmit = async (e) => {

    //     e.preventDefault()
    //     setStatus("Sending...");
    //     let myForm = document.getElementById('pizzaOrder');
    //     let formData = new FormData(myForm);
    //     let response = await fetch('/', {
    //         method: 'POST',
    //         headers: { "Content-Type": "application/x-www-form-urlencoded" },
    //         body: new URLSearchParams(formData).toString()
    //     }).then(() => console.log('Form successfully submitted')).catch((error) =>
    //         alert(error));
    //     setStatus("Submit");
    //     let result = await response.json();
    //     alert(result.status);
    // };
    return (
        <form name="pizzaOrder" method="POST" data-netlify="true" action="/ThankYou">
            <input type="hidden" name="form-name" value="pizzaOrder" />
            <div>
                <label htmlFor="text">Your Name:</label>
                <input id="name" type="text" name="name" />
            </div>
            <div>
                <label htmlFor="email">Your Email:</label>
                <input id="email" type="email" name="email" />
            </div>
            <div>
                <label htmlFor="message">Message:</label>
                <textarea id="message" name="message" />
            </div>
            <button type="submit">Send</button>
        </form>
    );
};

export default ContactForm;