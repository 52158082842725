import React from "react"
import Layout from "../components/layout"
import Metadata from "../components/metadata"
import "./contact.module.scss"
import ContactForm from "../components/contactus"

// const styles = {
//   visibility: "hidden",
// }

const Contact = () => {

  return (
    <Layout>
      <Metadata title="Contact" description="Coaster Experts Contact Page" />
      <ContactForm />
      {/* <div className="container">
        <h3>Contact Form</h3>
        <form name="contact" method="POST" data-netlify="true">
          <label htmlFor="first_name">First Name</label>
          <input name="first_name" type="text" required placeholder="John" />
          <label htmlFor="last_name">Last Name</label>
          <input name="last_name" type="text" required placeholder="Doe" />
          <label htmlFor="email">Email</label>
          <input name="email" type="email" required placeholder="you@domain.com" />
          <label htmlFor="message">Message</label>
          <textarea name="message" cols="30" rows="10" required> </textarea>
          <div className="center">
            <input type="submit" value="Submit" />
          </div>
        </form>
      </div> */}
    </Layout>
  )
}

export default Contact
